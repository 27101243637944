import windowLaunch from './libs/windowLaunch';
import socialWall from './libs/socialWall';

windowLaunch();

// Exterion
if(document.getElementById('blocks-1080-1920-exterion')) { socialWall('#blocks-1080-1920-exterion'); }

// O2 screens
if(document.getElementById('blocks-1080-1920')) { socialWall('#blocks-1080-1920', 4, 8, 'jbb-2017-o2'); }
if(document.getElementById('blocks-320-528')) { socialWall('#blocks-320-528', 4, 8, 'jbb-2017-o2');  }
if(document.getElementById('blocks-1920-1080')) { socialWall('#blocks-1920-1080', 0, 7, 'jbb-2017-o2');  }
if(document.getElementById('blocks-641-486')) { socialWall('#blocks-641-486', 0, 5, 'jbb-2017-o2');  }
if(document.getElementById('blocks-832-416')) { socialWall('#blocks-832-416', 0, 7, 'jbb-2017-o2');  }
if(document.getElementById('blocks-1024-768')) { socialWall('#blocks-1024-768', 0, 7, 'jbb-2017-o2');  }
if(document.getElementById('blocks-768-384')) { socialWall('#blocks-768-384', 0, 7, 'jbb-2017-o2');  }