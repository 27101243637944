/* 
	load blocks 
	pull in all image paths
	populate each block with 2 els with bg images
	front and back elements
	once loaded first time, when api call is made again
	replace all of the block content with the new images

	so...
	build 8 blocks
	get 16 image paths
	populate blocks with block.front and block.back els
	add classes systematically to animate each block in turn
	make api call and continue
*/

import { hasClass, addClass, removeClass } from './classUtils';

export default function socialWall(
	container = '#blocks', 
	highlighted = 4, 
	blocksTotal = 8, 
	feedTitle = 'jbb-2017'
) {
	// Setup the loop options
	const options = {
		container: container,
		blocksTotal: blocksTotal, // total blocks showing on screen at any one time
		contentTotal: blocksTotal*2, // total images to get from 1 page of files
		apiURL: 'https://api.tintup.com/v1/feed/'+ feedTitle +'?api_token=a7b6bf8d32641d3f4f6fd078732aad09eb39373b&',
		timeBetweenAnims: 2000,
		timeGetNewData: 35000,
		blockHighlighted: highlighted
	}

	let animateBlocks; // the animation interval variable (used for clearing to stop/start anim func)

	// init social wall and loop
	function socialWallBasic() {
		buildBlocks(options.blocksTotal);
		buildContent(options.blocksTotal, options.contentTotal, false);

		setInterval(function(){
			animateBlocksStop();
			buildContent(options.blocksTotal, options.contentTotal, true);
		}, options.timeGetNewData);
	}
	socialWallBasic();

	function buildContent(itemsShown, itemsAll, rebuild) {
		// Grab the data from API and build blocks
	    // make AJAX call using jsonp as per the TINT docs http://developers.tintup.com/
		$.ajax({
			url: options.apiURL+itemsAll,
		    jsonp: "callback",
		    dataType: "jsonp",
		    error: () => {
		    	console.log('The API appears to unavailable at this time. Please refresh to try again.');
		    },
		    success: function(response) {
		    	// now pass these params to generate the image els
	    		let responseFirst, responseSecond;
	    		responseFirst = response;
	    		if(response.data.length < options.blocksTotal) {
	    			console.log('There are not enough images in the feed, please add at least '+options.contentTotal);
	    		} else {
		    		// make another call to get next page
					$.ajax({
						url: responseFirst.next_page,
					    jsonp: "callback",
					    dataType: "jsonp",
					    success: function(responseNextPage) {
					    	// now pass these params to generate the image els for swapping
					    	responseSecond = responseNextPage;
							if(rebuild == false) {
						    	generateContent(itemsAll, itemsShown, responseFirst);
						    	setTimeout(showBlocks, 1500);
							}
					    	animateBlocksStart(itemsAll, responseFirst, responseSecond);
					    }
					});
	    		}
		    }
		});
	}

	function generateContent(itemsAll, itemsShown, response) {
		// Build the inner blocks with data from API
		let elsAll = itemsAll,
			elsShown = itemsShown,
			imgPaths = [], 
			videoPaths = [];

	    for (let i=0; i < elsAll; i++) {
	    	let img, video;

	    	// Populate array of image filenames
	    	img = response.data[i].original_image;
	    	imgPaths.push(img);

	    	// get video filenames
	    	video = response.data[i].embed;
	    	if(video !== '') {
				video = JSON.parse(video);

				// twitter has this property within the 'embed' object
				// if it's a static image. So have to detect in order to
				// filter out tweets with no video 
				if(response.data[i].source == 2) {
					let videoType = video.type;
					if(videoType !== 'video') {
				    	videoPaths.push(false); 
					} else {
						video = video.video_data.url;
				    	videoPaths.push(chooseVideo(video));
				    }
				} else {
					video = video.html;
			    	videoPaths.push(chooseVideo(video));
				}
	    	} else { 		
		    	videoPaths.push(false); // make video paths array same length by adding one el on
	    	}
	    }

		// Create the inner block image els and array of images currently shown (front side)
	    for (let i=0; i < elsShown; i++) {
	    	let img = imgPaths[i];
	    	let video = videoPaths[i];
			createInnerEl(i, 'front', img, video);
	    }

	    // Generate array of els not in the Shown array i.e. Hidden els (back side)
	    for (let j=0; j < elsAll; j++) {
	    	if(j > elsShown-1){
	        	let img = imgPaths[j];
	        	let video = videoPaths[j];
				createInnerEl(j - elsShown, 'back', img, video);
	    	}
	    }
	}

	function showBlocks() {
		// Flip random blocks to show other side
		let randomNums = generateRandomNumbers(options.blocksTotal);
		for (let i = 0; i < options.blocksTotal; i++) {
			let block = document.getElementById('block' + randomNums[i]);
			flipShow(block, i);
		}
	}

	function getCurrentFiles(itemTotal, currentItems) {
		// Build array of current img filenames 
		// (don't need video paths as imgs are used as video poster)
		let imgPaths = [], 
			imgCurrent;
	    for (let i=0; i < itemTotal; i++) {
	    	if(hasClass(currentItems[i], 'block-inner--video')) {
		    	imgCurrent = currentItems[i].poster;
	    	} else {
				imgCurrent = currentItems[i].currentStyle || window.getComputedStyle(currentItems[i], false),
				imgCurrent = imgCurrent.backgroundImage.slice(5, -2);
	    	}
	    	imgPaths.push(imgCurrent);
	    }	
	    return imgPaths;
	}

	function createInnerEl(item, side, image, video){
		if(video !== false) {
			// create the video el
			const blockVideo = document.createElement('video');

			blockVideo.setAttribute('poster', image);
			blockVideo.setAttribute('src', video);
	    	blockVideo.classList.add('block-inner');
			blockVideo.classList.add('block-inner--'+side);
	    	blockVideo.classList.add('block-inner--video');
			blockVideo.autoplay = true;
			blockVideo.loop = true;
			blockVideo.muted = true;
			let blockOuter = document.getElementById('block'+item);
			blockOuter.querySelector('.block-shape').appendChild(blockVideo);
		} else {
		    // Create the actual image els within blocks
			const blockInner = document.createElement('div');
	    	blockInner.classList.add('block-inner');
			blockInner.classList.add('block-inner--'+side);
			blockInner.style.backgroundImage = 'url('+ image + ')';
			const blockOuter = document.getElementById('block'+item);
			blockOuter.querySelector('.block-shape').appendChild(blockInner);
			const blockShape = blockOuter.querySelector('.block-shape');
		}

		// Hide the loading images
		const blockShapes = document.getElementsByClassName('block-shape');
		setTimeout(function(){
			for (let i=0; i < blockShapes.length; i++) {
				addClass(blockShapes[i], 'block-shape--hide');
			}
		}, 1700);
	}

	// Generate an array of random numbers
	function generateRandomNumbers(totalNums) {
		let randomNums = [];
		while(randomNums.length < totalNums){
		    let randomNum = Math.floor(Math.random() * totalNums);
		    if(randomNums.indexOf(randomNum) > -1) continue;
		    randomNums[randomNums.length] = randomNum;
		}
		return randomNums;
	}

	function flipShow(item, itemNum) {
		setTimeout(function(){
	    	item.classList.add('animate-scale-in');
		}, 50 * itemNum);
	}

	function flipHide(item, itemNum) {
		setTimeout(function(){
	    	item.classList.add('animate-scale-out');
		}, 50 * itemNum);
	}

	function buildBlocks(items) {
		let itemNum = items,
			blocks = [];

		// Generate the outer block structure
		for (let i=0; i<itemNum; i++) {
		    blocks.push('<div class="block-outer block-outer'+i+'"><div id="block'+ i +'" class="block block'+ i +'"><div class="block-shape"></div></div></div>');
		}
		$(options.container).html(blocks);
	}

	function destroyBlocks() {
		// Apply flip and hide function to 
		// all of the blocks in random sequence to then remove them all from view
		let randomNums = generateRandomNumbers(options.blocksTotal);
		for (let i = 0; i < options.blocksTotal; i++) {
			let block = document.getElementById('block' + randomNums[i]);
			flipHide(block, i);
		}
	}

	// Select Video from filenames
	function chooseVideo(fileName){
		let videoPath = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length) || fileName;
		const extensions = ['jpg','JPG','JPEG','gif','png'];
		if(extensions.indexOf(videoPath) == -1) {
			return fileName;
		} else {
			return false;
		}
	}

	function animateBlocksStart(itemsAll, response, responseNext) {
		// start animating the blocks in random sequence 
		// and switch around the images when out of view
		setTimeout(function(){
			let item, randomNumber, currRandomNumber, counter = 0;
			const blockHighlighted = document.getElementById('block'+options.blockHighlighted);

		    animateBlocks = setInterval(function(){
		    	counter++;

		    	function checkCounterAndRun(){
			    	if(counter > 3 && counter <= 6) {
						addClass(blockHighlighted, 'animate-highlight');
						animateSingle(options.blockHighlighted);
			    	} else if(counter == 7) {
						removeClass(blockHighlighted, 'animate-highlight');
						setTimeout(animateSingle, 500);
			    	} else {
						animateSingle();
			    	}
			    }
			    checkCounterAndRun();

				function animateSingle(excluded) {
					randomNumber = Math.floor(Math.random() * (options.blocksTotal-1));

					// if it's not the same as last time around run the anims, or get another random number
					if(randomNumber == currRandomNumber || randomNumber == excluded) { 
						checkCounterAndRun();
					} else {
						currRandomNumber = randomNumber;
						flipSwitchInit(currRandomNumber);
					}
				}

				function flipSwitchInit(number) {
					item = document.getElementById('block'+number);
					window.requestAnimationFrame(flipImage);
	
					setTimeout(function(){
						switchFile();
					}, 1400);					
				}

				function flipImage() {
					if(hasClass(item, 'animate-flipped')){
						removeClass(item, 'animate-flipped');
						addClass(item, 'animate-unflipped');
					} else {
						addClass(item, 'animate-flipped');
						removeClass(item, 'animate-unflipped');
					}
				}

				function switchFile() {
					// when flipped, grab a new image from the complete array 
					// and make new inner block from this within this block
					// so we need the full array combined of images
					// then we need to run the replace content file when it flips				
					let elsAll = itemsAll,
						currentImgPaths = [],
						newImgPaths = [],
						newVideoPaths = [],
						nextPageData = responseNext,
						innerBlocks = document.getElementsByClassName("block-inner");

					// get current image paths from blocks in DOM
					currentImgPaths = getCurrentFiles(elsAll, innerBlocks);

					// get first page img and video files
				    for (let i=0; i < elsAll; i++) {
				    	let img, video;

				    	// Populate array of image filenames
				    	img = response.data[i].original_image;
				    	newImgPaths.push(img);

				    	// get video filenames
				    	video = response.data[i].embed;
				    	if(video !== '') {
							video = JSON.parse(video);
							if(response.data[i].source == 2) {
								let videoType = video.type;
								if(videoType !== 'video') {
							    	newVideoPaths.push(false); 
								} else {
									video = video.video_data.url;
							    	newVideoPaths.push(chooseVideo(video));
							    }
							} else {
								video = video.html;
						    	newVideoPaths.push(chooseVideo(video));
							}
				    	} else { 		
					    	newVideoPaths.push(false); // make video paths array same length by adding one el on
				    	}
				    }
				    
				    // Get next page img and video files and add to arrays
				    for (let i=0; i < nextPageData.data.length; i++) {
				    	let imgNP, videoNP;

				    	// Populate array of image filenames
				    	imgNP = nextPageData.data[i].original_image;
				    	newImgPaths.push(imgNP);

				    	// get only video filenames and push to array
				    	videoNP = nextPageData.data[i].embed;
				    	if(videoNP !== '') {
							videoNP = JSON.parse(videoNP);

							if(nextPageData.data[i].source == 2) {
								let videoType = videoNP.type;
								if(videoType !== 'video') {
							    	newVideoPaths.push(false); 
								} else {
									videoNP = video.video_data.url;
							    	newVideoPaths.push(chooseVideo(videoNP));
							    }
							} else {
								videoNP = videoNP.html;
						    	newVideoPaths.push(chooseVideo(videoNP));
							}
				    	} else { 		
					    	newVideoPaths.push(false); // make video paths array same length by adding one el on
				    	}

					}

					// loop through new array and swap if have new data
				    for (let j=0; j < newImgPaths.length - 1; j++) {
				    	let imgNew, videoNew;
				    	let randomNum = Math.floor(Math.random() * newImgPaths.length);

				    	// get image filenames
				    	imgNew = newImgPaths[randomNum];

				    	// get video filenames if exist
				    	videoNew = newVideoPaths[randomNum];

				    	// new img filename is in current filenames array, do a swap
				    	compareFilesToSwap(imgNew, videoNew);

				    	function compareFilesToSwap(imageFile, videoFile){
							let isVideo;
							isVideo = (videoFile == false) ? false : true;

					    	if(currentImgPaths.indexOf(imageFile) == -1) {
						    	if(hasClass(item, 'animate-flipped')){

									// replace the back inner item paths with img or video
									let itemInner = item.querySelector('.block-inner--front');
									replaceContentFile(itemInner, imageFile, videoFile, isVideo);
						    	} else {
									let itemInner = item.querySelector('.block-inner--back');
									replaceContentFile(itemInner, imageFile, videoFile, isVideo);
						    	}
					    	} else {
					    		// run it again with new number
						    	randomNum = Math.floor(Math.random() * newImgPaths.length);
						    	imageFile = newImgPaths[randomNum];
						    	videoFile = newVideoPaths[randomNum];
					    		compareFilesToSwap(imageFile, videoFile);
					    	}
				    	}
				    }
			   }

			}, options.timeBetweenAnims);
		}, 500);
	}

	function animateBlocksStop() {
		// stop animating the blocks
		clearInterval(animateBlocks);
	}

	function getCurrentFile(elem) {
		// get current img file from style or poster attribute
		let imgPath;
		if(hasClass(elem, 'block-inner--video')) {
	    	imgPath = elem.poster;
		} else {
			imgPath = elem.currentStyle || window.getComputedStyle(elem, false),
			imgPath = imgPath.backgroundImage.slice(5, -2);
		}
	    return imgPath;
	}

	// Replace content of inner block with vid or img filename
	function replaceContentFile(item, img, video, isVideo) {
		if(isVideo == true) {
			if(hasClass(item, 'block-inner--video')){
				item.src = video;
				item.poster = img;
				item.style = '';
			} else {
				let newVideoEl = document.createElement('video');
				newVideoEl.setAttribute('poster', img);
				newVideoEl.setAttribute('src', video);
		    	newVideoEl.classList.add('block-inner');
		    	newVideoEl.classList.add('block-inner--video');
				newVideoEl.autoplay = true;
				newVideoEl.loop = true;
				newVideoEl.muted = true;
				newVideoEl.style = '';
				if(hasClass(item, 'block-inner--back')) {
					newVideoEl.classList.add('block-inner--back');
				} else {
					newVideoEl.classList.add('block-inner--front');
				}
				let itemParent = item.parentNode;
				itemParent.appendChild(newVideoEl);
				itemParent.removeChild(item);
			}
		} else {
			if(hasClass(item, 'block-inner--video')){		
				let newEl = document.createElement('div');
		    	newEl.classList.add('block-inner');
				newEl.style.backgroundImage = 'url('+ img + ')';			
				if(hasClass(item, 'block-inner--back')) {
					newEl.classList.add('block-inner--back');
				} else {
					newEl.classList.add('block-inner--front');
				}
				let itemParent = item.parentNode;
				itemParent.appendChild(newEl);
				itemParent.removeChild(item);
			} else {
				item.style.backgroundImage = 'url('+ img + ')';			
			}
		}  		
	}

}